import cn from 'classnames';

interface Props {
  classname?: string;
  width: string;
  height: string;
}
const Financial = (props: Props) => {
  const { classname } = props;
  const rootCn = cn(classname, { 'fill-[white]': !classname });
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={49.999} height={50} viewBox='0 0 49.999 50' {...props}>
      <path
        fill='#fff'
        d='M24.999 50a25.006 25.006 0 0 1-9.731-48.035 25 25 0 0 1 27.41 40.712A24.933 24.933 0 0 1 24.999 50Zm0-47a22 22 0 1 0 22 22 22.025 22.025 0 0 0-22-22Zm0 42a20.005 20.005 0 0 1-7.784-38.429A20.006 20.006 0 0 1 32.783 43.43 19.873 19.873 0 0 1 24.999 45Zm-6.53-15.88v1.95a4.237 4.237 0 0 0 1.125 3.165 4.414 4.414 0 0 0 3.195 1.1h1.05v3.18h3.39V35.33h.779a4.6 4.6 0 0 0 3.3-1.1 4.219 4.219 0 0 0 1.14-3.172V27a3.23 3.23 0 0 0-2.782-3.3l-6.331-1.646a1.112 1.112 0 0 1-.664-.374 1.255 1.255 0 0 1-.181-.731V18.5c0-.693.4-1.045 1.2-1.045h3.509c.776 0 1.17.353 1.17 1.05v2.07h3.84V18.44a4.27 4.27 0 0 0-1.11-3.165 4.4 4.4 0 0 0-3.21-1.094h-.66V11h-3.39v3.18h-.75a4.6 4.6 0 0 0-3.3 1.094 4.2 4.2 0 0 0-1.14 3.165v3.27a3.4 3.4 0 0 0 .735 2.31 3.492 3.492 0 0 0 1.755 1.11l6.57 1.741a.989.989 0 0 1 .87 1.02v3.12c0 .7-.394 1.049-1.17 1.049h-3.9c-.8 0-1.2-.353-1.2-1.049v-1.89Z'
        data-name='Subtraction 20'
      />
    </svg>
  );
};
export default Financial;
